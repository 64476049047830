import React from 'react'
import PropTypes from 'prop-types'
import { roofPropType } from 'lib/prop-types'
import {
  constants,
  drawBorder,
  getOrientationString,
  isFlatRoof,
  jumpTo,
  monthlyYield,
  efficiencyTranslation,
} from 'lib/solarcheck/solarcheck'
import formatNumber from 'lib/format-number'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { roundToTen } from '../../lib/format-number'

const { flatRoofPanelInclination } = constants

class RoofBox extends React.Component {
  constructor(props) {
    super(props)

    if (!props.monthlyYield) {
      this.calculateMonthlyYield(props.roof)
    }
  }

  calculateMonthlyYield(roof) {
    const result = monthlyYield(roof)
    if (this.props.monthlyYield !== result) {
      this.props.updateMonthlyYield(result)
    }
    return result
  }

  render() {
    const { roof, map, monthlyYield } = this.props

    return (
      <div
        key={`roof-${roof.featureId}`}
        className={`roof roof--hover ${
          this.props.selected ? 'roof--highlighted' : null
        }`}
        onClick={() => {
          if (typeof map !== 'undefined' && roof.featureId) {
            this.props.onSelectRoof()
            jumpTo(
              roof.geometry.rings[0][0][1],
              roof.geometry.rings[0][0][0],
              map,
              obj => this.props.onJump(obj)
            )
            drawBorder(roof.geometry.rings, map)
          }
        }}
      >
        <div className="roof__summary">
          <div className="roof__summary--item">
            <div className="roof__summary--title">Sommerhalbjahr</div>
            <div className="roof__summary--value">
              {formatNumber(roundToTen(this.props.production?.summer))} kWh
            </div>
          </div>
          <div className="roof__summary--item">
            <div className="roof__summary--title">Winterhalbjahr</div>
            <div className="roof__summary--value">
              {formatNumber(roundToTen(this.props.production?.winter))} kWh
            </div>
          </div>
        </div>

        {roof.attributes.klasse ? (
          <div className="roof__item">
            <span>Eignung:</span>
            <span className="roof__value">
              {efficiencyTranslation[roof.attributes.klasse]}
            </span>
          </div>
        ) : null}
        <div className="roof__item">
          <span>Fläche:</span>
          <span className="roof__value">
            {roof.attributes.flaeche ? (
              <span>
                {Math.floor(roof.attributes.flaeche)}m<sup>2</sup>
              </span>
            ) : (
              'Keine Angabe'
            )}
          </span>
        </div>
        <div className="roof__item">
          <span>{isFlatRoof(roof) ? 'Aufständerung:' : 'Neigung:'}</span>
          <span className="roof__value">
            {typeof roof.attributes.neigung !== 'undefined'
              ? `${
                  isFlatRoof(roof)
                    ? flatRoofPanelInclination
                    : roof.attributes.neigung
                }°`
              : 'Keine Angabe'}
          </span>
        </div>
        <div className="roof__item">
          <span>Ausrichtung:</span>
          <span className="roof__value">
            {typeof roof.attributes.ausrichtung !== 'undefined'
              ? getOrientationString(
                  // panels on flat roofs are always facing south
                  isFlatRoof(roof) ? 0 : roof.attributes.ausrichtung
                )
              : 'Keine Angabe'}
          </span>
        </div>
        {roof.attributes.gstrahlung && roof.attributes.flaeche ? (
          <div className="roof__item">
            <span>Spezifischer Ertrag:</span>
            <span className="roof__value">
              {formatNumber(monthlyYield ?? this.calculateMonthlyYield(roof))}{' '}
              kWh/kWp
            </span>
          </div>
        ) : null}
        <div
          onClick={e => {
            e.stopPropagation()
            this.props.removeRoof(roof)
          }}
          className="roof--remove"
        >
          <FontAwesomeIcon icon={faTimesCircle} className="icon-btn" />
        </div>
      </div>
    )
  }
}

RoofBox.propTypes = {
  onSelectRoof: PropTypes.func,
  onJump: PropTypes.func,
  updateMonthlyYield: PropTypes.func,
  removeRoof: PropTypes.func,
  selected: PropTypes.bool,
  roof: roofPropType,
  map: PropTypes.object,
  monthlyYield: PropTypes.number,
  production: PropTypes.shape({
    year: PropTypes.number,
    summer: PropTypes.number,
    winter: PropTypes.number,
  }),
}

export default RoofBox

import React, { Component, Fragment } from 'react'
import Legend from './Legend'
import LocationAutoSuggest from './LocationAutoSuggest'
import LocateMe from './LocateMe'
import HouseBox from './HouseBox'
import Layout from 'components/Layout'
import createStore from 'lib/util/create-store'
import {
  jumpTo,
  getRoof,
  drawBorder,
  getAddress,
  clearRoofBorder,
} from 'lib/solarcheck/solarcheck'
import isEmpty from 'lodash.isempty'
import createMap from 'lib/solarcheck/create-map'
import reconstructMap from 'lib/solarcheck/reconstruct-map'
import deconstructMap from 'lib/solarcheck/deconstruct-map'
import { faArrowLeft, faCheck, faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SolarcheckForm from './Form'
import Button from '../Button'
import {
  clearLayer,
  efficiencyTranslation,
  newCheckmarkLayer,
  showCheckmark,
} from '../../lib/solarcheck/solarcheck'
import Link from '../Link'

const defaultSolarplanerState = {
  showRoofLayer: true,
  houseInfo: {},
  isLocating: false,
  coordinates: { x: 0, y: 0 },
  monthlyYield: {},
  checkmarkLayers: {},
}

const configurationStore = createStore('solarcheck')

class Solarcheck extends Component {
  constructor(props) {
    super(props)
    this.handleClickOnMap = this.handleClickOnMap.bind(this)
  }

  state = { ...defaultSolarplanerState }

  buildMapFromData(loaded) {
    if (!isEmpty(loaded)) {
      const restoredMap = reconstructMap(loaded)
      this.setState({ ...restoredMap }, () => {
        this.addClickListener()
      })
    } else {
      this.setState({ map: createMap(this.mapRef) }, () => {
        this.addClickListener()
      })
    }
  }

  componentDidMount() {
    this.buildMapFromData(configurationStore.load())
  }

  componentDidUpdate() {
    if (this.state.map) {
      this.state.map.getLayers().item(1).setVisible(this.state.showRoofLayer)
    }
  }

  componentWillUnmount() {
    if (this.state.map) {
      this.state.map.un('singleclick', this.handleClickOnMap)
    }
  }

  handleClickOnMap(e) {
    const coord = e.coordinate
    this.setState(
      {
        coordinates: { x: coord[1], y: coord[0] },
      },
      async () => {
        if (typeof this.state.map === 'undefined') {
          return
        }

        const selectedRoof = await getRoof(
          this.state.coordinates,
          this.state.map
        )
        if (selectedRoof) {
          drawBorder(selectedRoof.geometry.rings, this.state.map)
          this.state.map?.getView().animate({ center: coord })

          if (
            Object.values(this.state.houseInfo).some(house =>
              house.roofs.some(
                roof => roof.featureId === selectedRoof.featureId
              )
            )
          ) {
            // roof already exists
            this.setState(
              { error: '', selectedRoof: selectedRoof.featureId },
              () => {
                this.saveToSessionStorage()
              }
            )
          } else {
            // new roof
            const address = await getAddress(this.state.map, coord)
            const addressString = address.parsedString

            const addressRoofs = this.state.houseInfo[addressString]
              ? this.state.houseInfo[addressString].roofs
              : []
            addressRoofs.push(selectedRoof)

            this.addCheckmark(selectedRoof)

            this.setState(
              {
                error: '',
                selectedRoof: selectedRoof.featureId,
                houseInfo: {
                  ...this.state.houseInfo,
                  [addressString]: {
                    ...this.state.houseInfo[addressString],
                    roofs: addressRoofs,
                    structuredAddress: address,
                  },
                },
              },
              () => {
                this.saveToSessionStorage()
              }
            )
          }
        } else if (Object.keys(this.state.houseInfo).length == 0) {
          // no previous roof
          this.setState({
            error:
              'Bitte eine gültige Adresse eingeben oder ein eingezeichnetes Dach aus der Karte auswählen',
          })
        }
      }
    )
  }

  addCheckmark(roof) {
    let layer
    const layerIndex = this.state.checkmarkLayers[roof.featureId]

    if (typeof layerIndex !== 'undefined') {
      layer = this.state.map.getLayers().item(layerIndex)
    } else {
      layer = newCheckmarkLayer()
      const newLayerIndex = this.state.map.getLayers().getLength()
      this.state.map.addLayer(layer)
      this.setState({
        checkmarkLayers: {
          ...this.state.checkmarkLayers,
          [roof.featureId]: newLayerIndex,
        },
      })
    }

    showCheckmark(layer, roof)
  }

  addClickListener() {
    this.state.map?.on('singleclick', this.handleClickOnMap)
  }

  addEfficiencyCheckbox() {
    return (
      <div className="roof-suitability__checkbox">
        <div className="checkbox__wrap inner">
          <input
            type="checkbox"
            id="showRoofLayer"
            className="checkbox--default"
            checked={this.state.showRoofLayer}
            onChange={() =>
              this.setState({ showRoofLayer: !this.state.showRoofLayer })
            }
          />
          <label htmlFor="showRoofLayer">
            <div className="checkbox">
              <FontAwesomeIcon icon={faCheck} />
            </div>
            Effizienz der Dachflächen
          </label>
        </div>
      </div>
    )
  }

  saveToSessionStorage() {
    if (isEmpty(this.state.houseInfo)) {
      configurationStore.clear()
      return
    }

    const deconstructedMap = deconstructMap({ ...this.state })
    const { monthlyYield, selectedRoof, houseInfo, checkmarkLayers } = this.state

    configurationStore.save({
      map: deconstructedMap,
      houseInfo,
      monthlyYield,
      selectedRoof,
      checkmarkLayers,
    })
  }

  render() {
    return (
      <Layout>
        <div className="section bg--grey">
          <div className="section__inner section--padded-small">
            <div className="wrap__box--pad-0 solarplaner__box bt__3--dark">
              <div className="column--60">
                <div className="map__box">
                  {this.state.showForm && (
                    <SolarcheckForm
                      solarcheckData={this.state.houseInfo}
                      monthlyYield={this.state.monthlyYield}
                    />
                  )}
                  <div
                    // removing it from the DOM entirely leads to weird issues with the map when switching back to the edit view
                    style={{ display: this.state.showForm ? 'none' : 'block' }}
                  >
                    <div
                      id="map"
                      className="map"
                      ref={element => (this.mapRef = element)}
                    >
                      {this.addEfficiencyCheckbox()}
                      <div className="map__offer-button">
                        <Button
                          className="btn btn--primary"
                          onClick={() => {
                            this.setState({ showForm: true })
                          }}
                        >
                          Offerte anfordern
                        </Button>
                      </div>
                    </div>

                    <div className="map__legend">
                      <Legend color="red">{efficiencyTranslation[5]}</Legend>
                      <Legend color="orange--dark">
                        {efficiencyTranslation[4]}
                      </Legend>
                      <Legend color="orange">{efficiencyTranslation[3]}</Legend>
                      <Legend color="yellow">{efficiencyTranslation[2]}</Legend>
                      <Legend color="blue">{efficiencyTranslation[1]}</Legend>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column--40 padding--all">
                {this.state.showForm ? (
                  <div className="margin--b">
                    <h2 className="bb__3--dark">Ihre Angaben</h2>
                    <a
                      onClick={() => {
                        this.setState({ showForm: false })
                      }}
                      href="#"
                      className="reverse-link"
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="link__icon--left"
                      />
                      Angaben bearbeiten
                    </a>
                  </div>
                ) : (
                  <div className="solarplaner__address">
                    <LocationAutoSuggest
                      onClick={address => {
                        if (this.state.map) {
                          jumpTo(address.x, address.y, this.state.map, obj =>
                            this.setState(obj)
                          )
                        }
                      }}
                    />
                    <div
                      onClick={() => {
                        this.setState({ isLocating: true })
                      }}
                    >
                      <LocateMe
                        onLocationFound={(x, y) => {
                          if (this.state.map) {
                            jumpTo(x, y, this.state.map, obj =>
                              this.setState(obj)
                            )
                          }
                          this.setState({ isLocating: false })
                        }}
                        onError={err => {
                          console.error(err)
                          this.setState({ error: err, isLocating: false })
                        }}
                      />
                    </div>
                  </div>
                )}
                {this.state.isLocating ? (
                  <div>Lokalisierung...</div>
                ) : this.state.error ? (
                  <div>{this.state.error}</div>
                ) : (
                  <Fragment>
                    {Object.keys(this.state.houseInfo).map(address => (
                      <HouseBox
                        address={address}
                        houseInfo={this.state.houseInfo[address]}
                        selectedRoof={this.state.selectedRoof}
                        map={this.state.map}
                        monthlyYield={this.state.monthlyYield}
                        updateMonthlyYield={(featureId, value) => {
                          this.setState({
                            monthlyYield: {
                              ...this.state.monthlyYield,
                              [featureId]: value,
                            },
                          })
                        }}
                        onJump={obj => this.setState(obj)}
                        onSelectRoof={featureId => {
                          if (this.state.selectedRoof !== featureId) {
                            this.setState({
                              selectedRoof: featureId,
                            })
                          }
                        }}
                        onRoofRemoved={roof => {
                          const newState = { ...this.state }

                          clearLayer(
                            this.state.checkmarkLayers[roof.featureId],
                            this.state.map
                          )

                          if (this.state.selectedRoof === roof.featureId) {
                            clearRoofBorder(this.state.map)
                            newState.selectedRoof = undefined
                          }

                          delete newState.monthlyYield[roof.featureId]

                          const houseRoofs = this.state.houseInfo[address].roofs
                          newState.houseInfo[address].roofs = houseRoofs.filter(
                            otherRoof => otherRoof != roof
                          )

                          if (newState.houseInfo[address].roofs.length === 0) {
                            delete newState.houseInfo[address]
                          }

                          this.setState({ ...newState }, () => {
                            this.saveToSessionStorage()
                          })
                        }}
                        key={address}
                      />
                    ))}
                    {Object.keys(this.state.houseInfo).length > 0 ? (
                      <a
                        onClick={() => {
                          Object.values(this.state.checkmarkLayers).forEach(
                            layerNumber =>
                              clearLayer(layerNumber, this.state.map)
                          )
                          clearRoofBorder(this.state.map)
                          this.setState(
                            { ...defaultSolarplanerState, showForm: false },
                            () => {
                              configurationStore.clear()
                            }
                          )
                        }}
                        href="#"
                        className="reverse-link"
                      >
                        <FontAwesomeIcon
                          icon={faUndoAlt}
                          className="link__icon--left"
                        />
                        Zurücksetzen
                      </a>
                    ) : (
                      <Fragment>
                        <div className="solarplaner__opening">
                          <h1>Lohnt sich Solarstrom für mich?</h1>
                          <p>
                            Unser Online-Photovoltaik-Check zeigt Ihnen sofort,
                            wie viel Strom Sie mit einer Photovoltaikanlage
                            erzeugen können.
                          </p>
                          <p>
                            <strong>
                              Geben Sie einfach Ihre Adresse ein und wählen Sie
                              mit einem Klick Ihr Dach auf der Karte aus
                            </strong>{' '}
                            - schon zeigt Ihnen der Photovoltaik-Check, welches
                            Ertragspotenzial auf Ihrer Dachfläche schlummert.
                          </p>
                          <p>
                            Wenn Sie genau wissen wollen, was die Ergebnisse für
                            Sie bedeuten, oder gleich mit der Planung Ihrer
                            Photovoltaikanlage beginnen möchten, können Sie sich
                            jederzeit gern{' '}
                            <Link to="/kontakt" className="link">
                              an unsere Experten wenden.
                            </Link>
                          </p>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
                <div className="solarplaner__footer confirm__actions">
                  <div className="flex-column">
                    {this.state.showForm ? null : (
                      <Button
                        className="btn btn--primary"
                        onClick={() => {
                          this.setState({ showForm: true })
                          // scroll to top
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                          })
                        }}
                      >
                        Offerte anfordern
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Solarcheck

import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import WMTSSource from 'ol/source/WMTS'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import { Style, Stroke, Fill } from 'ol/style'
import isEmpty from 'lodash.isempty'
import get from 'lodash.get'
import { checkmarkLayerStyle, constants, drawBorder, showCheckmark } from './solarcheck'

const { resolutions, mapProj } = constants

const defaultSolarplanerState = {
  showRoofLayer: true,
  houseInfo: {},
  isLocating: false,
  coordinates: { x: 0, y: 0 },
  monthlyYield: {},
}

const restoreLayer = layer => {
  if (layer.source === 'wmts') {
    const tileGrid = new WMTSTileGrid({
      origin: [layer.sourceOptions?.extent[0], layer.sourceOptions?.extent[3]],
      resolutions: resolutions,
      matrixIds: resolutions.map((resolution, i) => i),
    })
    return new TileLayer({
      source: new WMTSSource({
        urls: layer.sourceOptions?.urls,
        tileGrid: tileGrid,
        layer: layer.sourceOptions?.layerName,
        requestEncoding: 'REST',
      }),
      opacity: layer.opacity,
    })
  }
  const style = {
    stroke: new Stroke(layer.style),
    fill: undefined,
  }

  if (layer.style?.fill) {
    style.fill = new Fill({ color: layer.style.fill })
  }

  return new VectorLayer({
    source: new VectorSource(),
    style: new Style(style),
  })
}

const getAllRoofs = loaded =>
  Object.values(loaded.houseInfo || {}).flatMap(address => address.roofs)

const restoreRoofBorder = (loaded, restored) => {
  const allRoofs = getAllRoofs(loaded)
  const selectedRoof = allRoofs.find(
    roof => roof.featureId == loaded.selectedRoof
  )
  const roofBorder = get(selectedRoof, 'geometry.rings')
  if (roofBorder) {
    drawBorder(roofBorder, restored.map)
  }
}

const restoreCheckmarks = (loaded, restored) => {
  const roofs = getAllRoofs(loaded)
  roofs.forEach(roof => {
    const layerIndex = loaded.checkmarkLayers[roof.featureId]
    const layer = restored.map.getLayers().item(layerIndex)
    layer.setStyle(checkmarkLayerStyle)

    showCheckmark(layer, roof)
  })
}

const reconstructMap = loaded => {
  if (isEmpty(loaded) || typeof loaded.map === 'undefined') {
    return {}
  }
  const { target, logo, view } = loaded.map

  const restored = {
    ...defaultSolarplanerState,
    ...loaded,
    map: new Map({
      target: target,
      logo: logo,
      layers: loaded.map.layers.map(layer => restoreLayer(layer)),
      view: new View({
        ...view,
        projection: mapProj,
      }),
    }),
  }

  restoreRoofBorder(loaded, restored)
  restoreCheckmarks(loaded, restored)

  return restored
}

export default reconstructMap

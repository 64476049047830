import React from 'react'
import PropTypes from 'prop-types'
import iconRoof from 'assets/roof_icon.svg'

const Legend = ({ color, children }) => {
  return (
    <figure className="map__legend--item">
      <img src={iconRoof} className={`item--${color}`} />
      <figcaption>{children}</figcaption>
    </figure>
  )
}

Legend.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Legend

import { transform as projTransform } from 'ol/proj'

const getPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve([position.coords.longitude, position.coords.latitude])
      },
      e => {
        reject(e)
      }
    )
  })
}

const locate = async () => {
  if ('geolocation' in navigator) {
    try {
      const coordinates = await getPosition()
      const transformedCoords = projTransform(
        coordinates,
        'EPSG:4326',
        'EPSG:21781'
      )
      return { x: transformedCoords[1], y: transformedCoords[0] }
    } catch (e) {
      return {
        error:
          'Fehler bei der Lokalisierung aufgetreten. Bitte eine gültige Adresse eingeben oder ein eingezeichnetes Dach aus der Karte auswählen',
      }
    }
  } else {
    return {
      error:
        'Lokalisierung nicht möglich. Bitte eine gültige Adresse eingeben oder ein eingezeichnetes Dach aus der Karte auswählen',
    }
  }
}

export default locate

const { constants } = require('./solarcheck')

export default state => {
  const { resolutions, extent, mapProj, roofBorderLayerNumber } = constants

  if (state.map && state.map.getLayers) {
    return {
      layers: state.map
        .getLayers()
        .getArray()
        .map((layer, i) => {
          if (i >= roofBorderLayerNumber) {
            const layerObj = { source: 'vector' }
            const style = layer.getStyle()
            if (typeof style === 'function') {
              return layerObj
            }
            layerObj.style = {
              color: style.getStroke().getColor(),
              width: style.getStroke().getWidth(),
            }
            if (style.getFill()) {
              layerObj.style.fill = style.getFill().getColor()
            }
            return layerObj
          }
          return {
            source: 'wmts',
            sourceOptions: {
              resolutions: resolutions,
              extent: extent,
              layerName: layer.getSource().getLayer(),
              urls: layer.getSource().getUrls(),
            },
            opacity: layer.getOpacity(),
          }
        }),
      target: 'map',
      logo: false,
      view: {
        extent: extent,
        resolutions: resolutions,
        projection: mapProj,
        center: state.map.getView().getCenter(),
        resolution: state.map.getView().getResolution(),
      },
    }
  } else {
    return {}
  }
}

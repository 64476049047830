import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import WMTSSource from 'ol/source/WMTS'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import Map from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import { Style, Stroke } from 'ol/style'
import View from 'ol/View'
import { getCenter as olGetCenter } from 'ol/extent'
import { constants } from './solarcheck'

const { WMTSBaseUrl, serviceBaseUrl, mapProj, resolutions, extent } = constants
const lang = 'de'

const wmtsSource = options => {
  const tileGrid = new WMTSTileGrid({
    origin: [extent[0], extent[3]],
    resolutions: resolutions,
    matrixIds: resolutions.map((resolution, i) => i.toString()),
  })
  const extension = options.format || 'png'
  const timestamp = options.timestamp || (options.timestamps || ['current'])[0]
  return new WMTSSource({
    url:
      WMTSBaseUrl +
      '1.0.0/{Layer}/default/' +
      timestamp +
      '/2056/' +
      '{TileMatrix}/{TileCol}/{TileRow}.' +
      extension,
    requestEncoding: 'REST',
    tileGrid: tileGrid,
    layer: options.serverLayerName,
  })
}

const addLayer = (map, layerOptions, i) => {
  if (typeof i === 'undefined') {
    i = map.getLayers().getLength()
  }
  const olLayer = new TileLayer({
    opacity: 0.6,
    source: wmtsSource(layerOptions),
  })
  map.getLayers().insertAt(i, olLayer)
}

const calculateResolution = mapDiv => {
  if (!mapDiv) {
    return resolutions[0]
  }

  // in meters
  const swissHeight = 242000
  const swissWidth = 377000

  const mapHeight = mapDiv.current?.clientHeight || 1
  const resolutionForHeight = swissHeight / mapHeight
  const mapWidth = mapDiv.current?.clientWidth || 1
  const resolutionForWidth = swissWidth / mapWidth
  const resolution = Math.max(resolutionForHeight, resolutionForWidth)
  return resolution
}

const createMap = mapDiv => {
  // We add a basic map
  const baseConfig = {
    format: 'jpeg',
    timestamps: ['current'],
    type: 'wmts',
    serverLayerName: 'ch.swisstopo.swissimage',
  }
  const baseLayer = new TileLayer({
    source: wmtsSource(baseConfig),
  })

  const roofBorderVector = new VectorLayer({
    source: new VectorSource(),
    style: new Style({
      stroke: new Stroke({
        color: [0, 0, 0, 1],
        width: 4,
      }),
    }),
  })

  const map = new Map({
    layers: [baseLayer, roofBorderVector],
    target: 'map',
    logo: false,
    view: new View({
      extent: extent,
      center: olGetCenter(extent),
      resolutions: resolutions,
      resolution: calculateResolution(mapDiv),
      projection: mapProj,
    }),
  })

  fetch(
    serviceBaseUrl + 'rest/services/api/MapServer/layersConfig?lang=' + lang
  )
    .then(res => res.json())
    .then(data => {
      const layer = data['ch.bfe.solarenergie-eignung-daecher']
      addLayer(map, layer, 1)
    })

  return map
}

export default createMap

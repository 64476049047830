import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { constants } from 'lib/solarcheck/solarcheck'

const { serviceBaseUrl } = constants

const strip = html => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

const getSuggestionValue = suggestion => strip(suggestion.label)

class LocationAutoSuggest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      suggestions: [],
      error: false,
    }
  }

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested({ value }) {
    if (value.length <= 3) return []

    fetch(
      `${serviceBaseUrl}rest/services/api/SearchServer?type=locations&lang=de&searchText=${encodeURI(
        value
      )}`
    )
      .then(res => {
        if (res.ok) {
          this.setState({ error: false })
          return res.json()
        } else {
          this.setState({ error: true })
          throw res.text()
        }
      })
      .then(data => {
        this.setState({
          suggestions: data.results.map(result => result.attrs),
        })
      })
      .catch(e => console.error(e))
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    })
  }

  renderSuggestion(suggestion, options) {
    return (
      <div className={options.isHighlighted ? 'highlighted' : ''}>
        {getSuggestionValue(suggestion)}
      </div>
    )
  }

  render() {
    const { value, suggestions } = this.state

    return (
      <>
        <div className="hm-input--wrap">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={value =>
              this.onSuggestionsFetchRequested(value)
            }
            onSuggestionsClearRequested={() =>
              this.onSuggestionsClearRequested()
            }
            getSuggestionValue={suggestion => getSuggestionValue(suggestion)}
            renderSuggestion={(suggestion, options) =>
              this.renderSuggestion(suggestion, options)
            }
            inputProps={{
              placeholder: 'Adresse eingeben',
              value,
              onChange: (e, changeEvent) =>
                this.setState({ value: changeEvent.newValue }),
            }}
            onSuggestionSelected={(e, { suggestion }) =>
              this.props.onClick(suggestion)
            }
          />

          {this.state.value?.length > 0 ? (
            <button
              type="reset"
              className="btn btn--small input--btn solarplaner__address--reset"
              onClick={() => {
                this.setState({ value: '' })
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          ) : null}
        </div>
        {this.state.error && (
          <div className="callout callout--error mv3">
            <span className="callout__icon">
              <FontAwesomeIcon icon={faExclamation} />
            </span>
            <span className="callout__text">
              Adressen konnten nicht geladen werden. Bitte wählen Sie ein Haus
              direkt in der Karte aus.
            </span>
          </div>
        )}
      </>
    )
  }
}

LocationAutoSuggest.propTypes = {
  onClick: PropTypes.func,
}

export default LocationAutoSuggest

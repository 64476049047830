import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelopeOpen,
  faExclamation,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import ContactFormFields from '../ContactFormFields'
import Button from '../Button'
import createStore from 'lib/util/create-store'
import Recaptcha, { generateCaptchaToken } from 'lib/recaptcha'
import pushDataLayer from 'lib/push-data-layer'
import invokeNetlifyLambda from 'lib/invoke-netlify-lambda'
import Link from '../Link'
import { getOrientationString, isFlatRoof } from 'lib/solarcheck/solarcheck'
import {
  efficiencyTranslation,
  productionPerRoof,
} from '../../lib/solarcheck/solarcheck'
import { roundToTen } from '../../lib/format-number'

const contactStore = createStore('contact')

const defaultContact = {
  firstName: '',
  lastName: '',
  street: '',
  zipCode: '',
  city: '',
  email: '',
  phone: '',
  notes: '',
}

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contact: this.prefillContact(),
      isSubmitting: false,
      success: false,
      error: false,
    }
  }

  prefillContact() {
    if (contactStore.load()) {
      return contactStore.load()
    }
    const contact = { ...defaultContact }

    const solarcheckAddress = Object.values(this.props.solarcheckData)[0]
      ?.structuredAddress
    if (solarcheckAddress) {
      contact.street = solarcheckAddress.strname_deinr
      contact.zipCode = solarcheckAddress.dplz4
      contact.city = solarcheckAddress.ggdename
    }
    return contact
  }

  stringifySolarcheckData() {
    return Object.keys(this.props.solarcheckData)
      .map(address => {
        const production = productionPerRoof(this.props.solarcheckData[address])

        const totalPerformance = Object.values(production).reduce(
          (sum, productionValues) => sum + productionValues.performance,
          0
        )

        const totalProduction = Object.values(production).reduce(
          (sum, productionValues) => {
            return sum + productionValues.year
          },
          0
        )

        const addressString = `Adresse: ${address}`

        const yearlyProduction = `Ungefähre Jahresproduktion: ${roundToTen(
          totalProduction
        )} kWh bei ${totalPerformance.toFixed(1)} kWp`

        const roofs = this.props.solarcheckData[address].roofs.map(
          (roof, i) => {
            const eignung = roof.attributes.klasse
              ? efficiencyTranslation[roof.attributes.klasse]
              : ''

            return [
              `-- Dach ${i + 1} --`,
              `Sommerhalbjahr: ${roundToTen(
                production[roof.featureId].summer
              )} kWh`,
              `Winterhalbjahr: ${roundToTen(
                production[roof.featureId].winter
              )} kWh`,
              `Eignung: ${eignung}`,
              `Fläche: ${Math.floor(roof.attributes.flaeche)}m²`,
              `Neigung: ${roof.attributes.neigung}°`,
              `Ausrichtung: ${getOrientationString(
                // panels on flat roofs are always facing south
                isFlatRoof(roof) ? 0 : roof.attributes.ausrichtung
              )}`,
              `Spezifischer Ertrag: ${
                this.props.monthlyYield[roof.featureId]
              }kWh/kWp`,
            ]
          }
        )
        return [addressString, yearlyProduction, roofs]
      })
      .flat(3)
      .join('\n')
  }

  render() {
    return (
      <div className="padding--all">
        <form
          onSubmit={e => {
            e.preventDefault()
            pushDataLayer('event', 'contact', { type: 'Photovoltaikcheck' })
            const GCLID = e.target?.GCLID?.value
            generateCaptchaToken().then(token => {
              const data = {
                ...this.state.contact,
                rawRoofData: this.stringifySolarcheckData(),
                typeOfForm: 'Solarcheck',
                GCLID,
                captchaToken: token,
              }
              this.setState({ isSubmitting: true })
              invokeNetlifyLambda
                .post('pardot-submit', JSON.stringify(data))
                .then(() => {
                  this.setState({
                    success: true,
                    error: false,
                    isSubmitting: false,
                  })
                })
                .catch(e => {
                  console.error(e)
                  this.setState({
                    success: false,
                    error: true,
                    isSubmitting: false,
                  })
                })
            })
          }}
        >
          <Recaptcha />
          <h2 className="bb__3--dark margin--b">Kontakt aufnehmen</h2>
          <ContactFormFields
            handleChange={e => {
              const newContact = {
                ...this.state.contact,
                [e.target.name]: e.target.value,
              }
              this.setState({ contact: newContact })
              contactStore.save(newContact)
            }}
            state={this.state.contact}
          />
          <div className="confirm__actions">
            <div className="confirm__submit">
              <Button
                className="btn btn--primary"
                disabled={this.state.isSubmitting}
              >
                {this.state.isSubmitting ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin margin--r05"
                  />
                ) : null}
                Offerte anfordern
              </Button>
            </div>
          </div>
          {this.state.error ? (
            <div className="callout callout--warning mv3">
              <span className="callout__icon">
                <FontAwesomeIcon icon={faExclamation} />
              </span>
              <span className="callout__text">
                Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.
              </span>
            </div>
          ) : this.state.success ? (
            <>
              <div className="callout callout--success mv3">
                <span className="callout__icon">
                  <FontAwesomeIcon icon={faEnvelopeOpen} />
                </span>
                <span className="callout__text">
                  Danke! Wir haben Ihre Nachricht erhalten und machen uns nun an
                  die Arbeit. Sie hören in Kürze von uns.
                </span>
              </div>
              <div className="contact__success--cta">
                <Link to="/konfigurator" className="btn btn--primary">
                  Heizungs-Check
                </Link>
                <div>
                  Zusätzlich an einer modernen Wärmepumpen-Lösung interessiert?
                  Dann ganz einfach den Heizungs-Check ausfüllen und absenden.
                </div>
              </div>
            </>
          ) : null}
        </form>
      </div>
    )
  }
}

export default Form

Form.propTypes = {
  solarcheckData: PropTypes.objectOf(
    PropTypes.shape({
      roofs: PropTypes.arrayOf(
        PropTypes.shape({
          attributes: PropTypes.shape({
            ausrichtung: PropTypes.number,
            flaeche: PropTypes.number,
            neigung: PropTypes.number,
            klasse: PropTypes.number,
            gstrahlung: PropTypes.number,
            stromertrag: PropTypes.number,
            stromertrag_sommerhalbjahr: PropTypes.number,
            stromertrag_winterhalbjahr: PropTypes.number,
          }),
          bbox: PropTypes.arrayOf(PropTypes.number),
          featureId: PropTypes.number,
          geometry: PropTypes.shape({
            rings: PropTypes.arrayOf(
              PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
            ),
          }),
          id: PropTypes.number,
          layerName: PropTypes.string,
        })
      ),
      structuredAddress: PropTypes.shape({
        strname: PropTypes.arrayOf(PropTypes.string),
        deinr: PropTypes.string,
        dplz4: PropTypes.number,
        ggdename: PropTypes.string,
      }),
    })
  ),
  monthlyYield: PropTypes.shape({ featureId: PropTypes.number }),
}

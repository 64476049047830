import { withPrefix } from 'gatsby'
const { get } = require('ol/proj')
const proj4 = require('proj4').default
const { register } = require('ol/proj/proj4')
const Feature = require('ol/Feature').default
const { Polygon, Point } = require('ol/geom')
const VectorLayer = require('ol/layer/Vector').default
const VectorSource = require('ol/source/Vector').default
const { Style, Icon } = require('ol/style')
const { getCenter } = require('ol/extent')

export const isFlatRoof = roof => {
  return roof.attributes.neigung <= 7
}

export const getOrientationString = orientation => {
  if (orientation > 157.5 || orientation < -157.5) {
    return 'Nord'
  }
  if (orientation > 112.5) {
    return 'Nord-West'
  }
  if (orientation > 67.5) {
    return 'West'
  }
  if (orientation > 22.5) {
    return 'Süd-West'
  }
  if (orientation > -22.5) {
    return 'Süd'
  }
  if (orientation > -67.5) {
    return 'Süd-Ost'
  }
  if (orientation > -112.5) {
    return 'Ost'
  }
  return 'Nord-Ost'
}

export const efficiencyTranslation = {
  1: 'Wenig geeignet',
  2: 'Mittelmäßig geeignet',
  3: 'Gut geeignet',
  4: 'Sehr gut geeignet',
  5: 'Hervorragend geeignet',
}

export const jumpTo = (x, y, map, onAfterJump) => {
  const view = map.getView()
  const source = view.getCenter()
  const sourceRes = view.getResolution()
  const dest = [y, x]
  let destRes = Math.min(constants.zoomResolution, sourceRes)
  //otherwise the zoom animation doesn't work :(
  if (destRes === sourceRes) {
    destRes *= 1.001
  }
  const dist = Math.sqrt(
    Math.pow(source[0] - dest[0], 2) + Math.pow(source[1] - dest[1], 2)
  )
  if (dist > 1000) {
    view.animate(
      {
        resolution: Math.max(sourceRes, destRes, dist / 1000),
      },
      { center: dest, resolution: destRes }
    )
  } else {
    view.animate({ center: dest, resolution: destRes })
  }
  if (typeof onAfterJump !== 'undefined') {
    onAfterJump({ coordinates: { x, y }, error: '' })
  }
}

proj4.defs(
  'EPSG:21781',
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs'
)
register(proj4)

const getMapProj = extent => {
  let mapProj = get('EPSG:21781')
  mapProj?.setExtent(extent)
  return mapProj
}

const extent = [420000, 30000, 900000, 350000]
export const constants = {
  resolutions: [
    4000, 3750, 3500, 3250, 3000, 2750, 2500, 2250, 2000, 1750, 1500, 1250,
    1000, 750, 650, 500, 250, 100, 50, 20, 10, 5, 2.5, 2, 1.5, 1, 0.5, 0.25,
    0.1,
  ],
  zoomResolution: 0.25,
  roofBorderLayerNumber: 2,
  extent: extent,
  mapProj: getMapProj(extent),
  serviceBaseUrl: 'https://api3.geo.admin.ch/',
  WMTSBaseUrl: 'https://wmts10.geo.admin.ch/',
  standardModulePeak: 0.43,
  standardModuleArea: 2,
  flatRoofBonusYield: 110,
  flatRoofPanelInclination: 10,
}

const getRoofBorderLayer = map => {
  return map.getLayers().item(constants.roofBorderLayerNumber)
}

export const drawBorder = (coordinates, map) => {
  // Clear the highlighted roof the add the new one
  const vectorLayer = getRoofBorderLayer(map)
  const polygon = new Polygon(coordinates)
  // Remove the previous roof highlighted
  vectorLayer.getSource().clear()
  vectorLayer.getSource().addFeature(new Feature(polygon))
}

export const clearRoofBorder = map => {
  clearLayer(constants.roofBorderLayerNumber, map)
}

export const clearLayer = (index, map) => {
  const layer = map.getLayers().item(index)
  if (layer) {
    layer.getSource().clear()
  }
}

export const checkmarkLayerStyle = (feature, resolution) => {
  const iconStyle = new Style({
    image: new Icon({
      src: withPrefix('/icons/checkmark.png'),
    }),
  })

  iconStyle.getImage().setScale(0.03 / resolution)
  return iconStyle
}

export const newCheckmarkLayer = () => {
  return new VectorLayer({
    renderMode: 'image',
    source: new VectorSource(),
    style: checkmarkLayerStyle,
  })
}

export const showCheckmark = (layer, roof) => {
  const center = new Feature(new Point(getCenter(roof.bbox)))
  layer.getSource().addFeature(center)
}

export const getRoof = ({ x, y }, map) => {
  return fetch(
    constants.serviceBaseUrl +
      'rest/services/api/MapServer/identify?' +
      'geometryType=esriGeometryPoint' +
      '&geometryFormat = geojson' +
      '&layers=all:ch.bfe.solarenergie-eignung-daecher' +
      '&geometry=' +
      y +
      ',' +
      x +
      '&mapExtent=' +
      map.getView().calculateExtent(map.getSize()).join(',') +
      '&imageDisplay=' +
      map.getSize().toString() +
      ',96' +
      '&tolerance=1' +
      '&order=distance' +
      '&lang=de'
  )
    .then(res => res.json())
    .then(data => {
      if (data.results && data.results.length > 0) {
        const roof = data.results[0]
        // featureId is negative if there has been an error e.g. the map was zoomed to far out
        if (roof.featureId > 0) {
          return roof
        }
      }
    })
}

export const getAddress = (map, coordinates) => {
  const mapExtent = map.getView().calculateExtent(map.getSize())
  const url =
    constants.serviceBaseUrl +
    'rest/services/api/MapServer/identify?' +
    'geometryType=esriGeometryPoint' +
    '&geometry=' +
    coordinates.toString() +
    '&imageDisplay=' +
    map.getSize().toString() +
    ',96' +
    '&mapExtent=' +
    mapExtent.toString() +
    '&tolerance=1000' +
    '&order=distance' +
    '&order=distance' +
    '&layers=all:ch.bfs.gebaeude_wohnungs_register' +
    '&returnGeometry=true' +
    '&lang=de'
  return fetch(url)
    .then(res => res.json())
    .then(data => {
      if (data.results.length === 0) {
        return { parsedString: 'Unbekannte Adresse' }
      }
      const address = data.results[0].attributes

      const street = address.strname && address.strname[0]
      const houseNumber = address.deinr
      const plz = address.plz_plz6?.split('/')[0]
      const city = address.ggdename

      return {
        ...address,
        parsedString: `${street || ''} ${houseNumber || ''}, ${plz || ''} ${
          city || ''
        }`,
      }
    })
    .catch(err => {
      console.error(err)
      return 'Fehler bei der Adressermittlung aufgetreten'
    })
}

export const monthlyYield = roof => {
  const { standardModuleArea, standardModulePeak, flatRoofBonusYield } =
    constants

  let result =
    Math.round(
      (roof.attributes.stromertrag ?? 0) /
        ((roof.attributes.flaeche / standardModuleArea) * standardModulePeak) /
        10
    ) * 10

  if (isFlatRoof(roof)) {
    result += flatRoofBonusYield
  }

  return result
}

export const getUtilizationFactor = roof => {
  if (!isFlatRoof(roof)) {
    return 0.75
  }

  const numberOfCorners = roof.geometry.rings[0]?.length

  if (typeof numberOfCorners === 'undefined' || numberOfCorners >= 12) {
    return 0.4
  }

  const cornerFactor = 0.00625 * (numberOfCorners - 5)
  return 0.45 - cornerFactor
}

export const productionPerRoof = houseInfo => {
  return houseInfo.roofs.reduce((acc, roof) => {
    const utilizationFactor = getUtilizationFactor(roof)

    const totalPerformance =
      (roof.attributes.flaeche *
        utilizationFactor *
        constants.standardModulePeak) /
      constants.standardModuleArea

    const production = monthlyYield(roof) * totalPerformance
    const summerProduction =
      (production / roof.attributes.stromertrag) *
      roof.attributes.stromertrag_sommerhalbjahr
    const winterProduction =
      (production / roof.attributes.stromertrag) *
      roof.attributes.stromertrag_winterhalbjahr

    acc[roof.featureId] = {
      performance: totalPerformance,
      year: production || 0,
      winter: winterProduction || 0,
      summer: summerProduction || 0,
    }

    return acc
  }, {})
}

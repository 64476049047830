import React from 'react'
import PropTypes from 'prop-types'
import RoofBox from './RoofBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import formatNumber from 'lib/format-number'
import isEqual from 'lodash.isequal'
import { productionPerRoof as calculateProductionPerRoof } from '../../lib/solarcheck/solarcheck'
import { roundToTen } from '../../lib/format-number'

class HouseBox extends React.Component {
  constructor(props) {
    super(props)
    const productionPerRoof = calculateProductionPerRoof(this.props.houseInfo)

    this.state = {
      productionPerRoof: productionPerRoof,
      totalProduction: this.totalProduction(productionPerRoof),
    }
  }

  componentDidUpdate() {
    const productionPerRoof = calculateProductionPerRoof(this.props.houseInfo)
    if (!isEqual(productionPerRoof, this.state.productionPerRoof)) {
      this.setState({
        productionPerRoof,
        totalProduction: this.totalProduction(productionPerRoof),
      })
    }
  }

  totalPerformance() {
    return Object.values(this.state.productionPerRoof).reduce(
      (sum, productionValues) => sum + productionValues.performance,
      0
    )
  }

  totalProduction(productionPerRoof) {
    return Object.values(productionPerRoof).reduce((sum, productionValues) => {
      return sum + productionValues.year
    }, 0)
  }

  render() {
    const { address, houseInfo, selectedRoof, map, monthlyYield } = this.props

    return (
      <div
        className="solarplaner__house b--grey-light b-r--3"
        key={`address-${address.replace(' ', '-')}`}
      >
        <FontAwesomeIcon icon={faHome} className="icon--house" />
        <h3 className="solarplaner__house--title">{address}</h3>

        <div className="roof__summary">
          <div className="roof__summary--item">
            <div className="roof__summary--title">
              Ungefähre Jahresproduktion
            </div>
            <div className="roof__summary--value">
              {formatNumber(roundToTen(this.state.totalProduction))} kWh ({formatNumber(this.totalPerformance().toFixed(1))} kWp)
            </div>
          </div>
        </div>
        {houseInfo.roofs.map((roof, i) => (
          <RoofBox
            selected={selectedRoof == roof.featureId}
            roof={roof}
            map={map}
            monthlyYield={monthlyYield[roof.featureId]}
            onSelectRoof={() => this.props.onSelectRoof(roof.featureId)}
            onJump={obj => this.props.onJump(obj)}
            removeRoof={roof => this.props.onRoofRemoved(roof)}
            updateMonthlyYield={value =>
              this.props.updateMonthlyYield(roof.featureId, value)
            }
            key={`roof-${address}-${i}`}
            production={this.state.productionPerRoof[roof.featureId]}
          />
        ))}
      </div>
    )
  }
}

HouseBox.propTypes = {
  onSelectRoof: PropTypes.func.isRequired,
  onRoofRemoved: PropTypes.func.isRequired,
  onJump: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  houseInfo: PropTypes.shape({
    roofs: PropTypes.arrayOf(
      PropTypes.shape({
        attributes: PropTypes.shape({
          ausrichtung: PropTypes.number,
          flaeche: PropTypes.number,
          neigung: PropTypes.number,
          klasse: PropTypes.number,
          gstrahlung: PropTypes.number,
          stromertrag: PropTypes.number,
          stromertrag_sommerhalbjahr: PropTypes.number,
          stromertrag_winterhalbjahr: PropTypes.number,
        }),
        bbox: PropTypes.arrayOf(PropTypes.number),
        featureId: PropTypes.number,
        geometry: PropTypes.shape({
          rings: PropTypes.arrayOf(
            PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
          ),
        }),
        id: PropTypes.number,
        layerName: PropTypes.string,
      })
    ),
    structuredAddress: PropTypes.shape({
      strname: PropTypes.arrayOf(PropTypes.string),
      deinr: PropTypes.string,
      dplz4: PropTypes.number,
      ggdename: PropTypes.string,
    }),
  }).isRequired,
  selectedRoof: PropTypes.number,
  map: PropTypes.object,
  monthlyYield: PropTypes.shape({ featureId: PropTypes.number }),
  updateMonthlyYield: PropTypes.func,
}

export default HouseBox

import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import geolocation from 'lib/solarcheck/geolocation'

const handleClick = async (e, { onLocationFound, onError }) => {
  e.preventDefault()
  const location = await geolocation()
  if (location && location.x && location.y) {
    onLocationFound(location.x, location.y)
  } else {
    onError(location.error)
  }
}

const LocateMe = props => (
  <button
    type="button"
    onClick={e => handleClick(e, props)}
    className="location--action btn btn--small input--btn"
    title="Meinen Standort finden"
  >
    <FontAwesomeIcon icon={faLocationArrow} />
  </button>
)

LocateMe.propTypes = {
  onLocationFound: PropTypes.func,
  onError: PropTypes.func,
}

export default LocateMe
